<template>
    <Dialog :header='headerText' v-model:visible="display" :modal="true" :closable="true" :class="`member-actions-modal-${step}`" @show='onShown' @hide='onHide'>
        <template v-if='step === 1'>
            <div class='permission-item' v-for="(option, index) in options" :key='index'>
                <span class='permission-label'>{{permissionLabels[index]}}</span>
                <SelectButton v-model="selectedOptions[index].value" :options="option" />
            </div>
        </template>
        <template v-else-if="step === 3">
            <div class="p-float-label">
                <Dropdown id="roleDropdown" class="role-dropdown" v-model="roleOption" optionLabel="name" :options="roleOptions" 
                optionDisabled="disabled" panelClass='role-dropdown-panel'/>
                <label for="roleDropdown" class='input-label'>Role</label>
                <table class="permissions-table" v-if="[GROUP_ADMIN_ROLE_TYPE['ID'], GROUP_MOD_ROLE_TYPE['ID']].includes(roleOption.id)">
                    <tr>
                        <th>Action/Permission</th>
                        <th>Allowed?</th>
                    </tr>
                    <tr v-for="value in permissionTableValues" :key="value">
                        <td>
                            {{value['HEADER']}}
                        </td>
                        <td>
                            <i :class="value['VALUE']"></i>
                        </td>
                    </tr>
                </table>
            </div>
        </template>
        <template v-else-if="step === 2">
            <div class="ban-checkbox-container">
                <Checkbox inputId="ban-checkbox" v-model="ban" :binary="true" v-tooltip.bottom="banTooltipMsg" />
                <label for="ban-checkbox" class="ban-checkbox-label" v-tooltip.bottom="banTooltipMsg">Ban this user</label>
            </div>
        </template>

        <template #footer>
            <div class='actions-container' v-if="step === 0">
                <Button label='Change Roles' class='blue-button' @click='step = 3' />
                <Button label='Edit Permissions' class='blue-button' @click='step = 1' :disabled="true"/>
                <Button label='Remove Member' class='red-button__primary' @click='step = 2' />
            </div>
            <template v-else>
                <!-- <Button label="Back" class='blue-button' @click='previousStep'/> -->
                <Button label="Cancel" class='blue-button' @click='closeDialog'/>
                <Button label='Remove' class='red-button__primary' @click='removeOrBanMember'  :loading="loading" v-if='step === 2'/>
                <Button label='Done' class="green-button__primary" :loading="loading" @click="updateGroupMemberRole" :disabled="disableIfNoChanges" v-else-if="step === 3"/>
            </template>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import SelectButton from 'primevue/selectbutton';
import Dropdown from 'primevue/dropdown';

import GroupService from '../../../service/GroupService';
import StringUtils from '../../../utilities/StringUtils';

import EventBus from '../../../event-bus';
import {mapState} from 'vuex';
import {GROUP_MEMBER_ROLE_TYPE, GROUP_ADMIN_ROLE_TYPE, GROUP_MOD_ROLE_TYPE} from '../../../common/constants';

export default {
    name: 'MemberActionsModal',
    props: {
        isGroupAdmin: {
            type: Boolean,
            required: true
        }
    },
    components: {
        Dialog, SelectButton, Checkbox, Dropdown
    },

    data() {
        return {
            // CONSTANTS
            GROUP_ADMIN_ROLE_TYPE,
            GROUP_MOD_ROLE_TYPE,

            display: false,
            loading: false,
            ban: false,
            banTooltipMsg: 'It will prevent the user from rejoining the group',
            step: 0,
            originalPerms: [],
            permissionLabels: [],
            options: [],
            selectedOptions: [],
            
            roleOption: {
                name: '',
                id: null
            },
            originalRole: {
                id: null,
                name: ''
            },
            selectedMember: {},
            stringUtils: StringUtils
        }
    },
    computed: {
        ...mapState(['selectedGroup']),

        roleOptions() {
            return this.getRoleOptions();
        },  

        headerText() {
            const fullName = this.stringUtils.fullName(this.selectedMember.analyst);

            const HEADER_TEXT_MAP = {
                0: 'Actions',
                1: `Edit ${fullName}'s Permissions`,
                2: `Are you sure you want to remove ${fullName}?`,
                3: `Change ${fullName}'s Role`
            }

            return HEADER_TEXT_MAP[this.step];
        },

        lastStepButtonLabel() {
            const LABEL_MAP = {
                1: 'Done',
                2: 'Remove',
                3: 'Done'
            }

            return LABEL_MAP[this.step];
        },

        disableIfNoChanges() {
            let val = false;

            if (this.step === 1) {
                return this.originalPerms.every((perm, index) => {
                    const actualVal = this.selectedOptions[index].value === 'Yes' ? true : false;

                    val = actualVal === perm;
                })
            } else if (this.step === 3) {
                val = this.originalRole.id === this.roleOption.id;
            }

            return val;
        },

        permissionTableValues() {
            let values = [];

            if (this.roleOption.id === GROUP_ADMIN_ROLE_TYPE['ID']) {
                values = [
                    'pi pi-times',
                    'pi pi-times',
                    'pi pi-check',
                    'pi pi-check',
                    'pi pi-check',
                    'pi pi-check',
                    'pi pi-check',
                    'pi pi-check',
                    'pi pi-check',
                    'pi pi-check',
                    'pi pi-check',
                    'pi pi-times',
                    'pi pi-check',
                    'pi pi-check',
                    'pi pi-check',
                    'pi pi-times',
                    'pi pi-check'
                ];
            } else if (this.roleOption.id === GROUP_MOD_ROLE_TYPE['ID']) {
                values = [
                    'pi pi-times',
                    'pi pi-times',
                    'pi pi-times',
                    'pi pi-times',
                    'pi pi-times',
                    'pi pi-check',
                    'pi pi-check',
                    'pi pi-check',
                    'pi pi-times',
                    'pi pi-times',
                    'pi pi-times',
                    'pi pi-times',
                    'pi pi-check',
                    'pi pi-check',
                    'pi pi-check',
                    'pi pi-times',
                    'pi pi-times',
                ];
            }


            const TABLE_VALUES = [{
                'HEADER': 'Delete Group',
                'VALUE': values[0]
                },
                {
                'HEADER': 'Edit Profile',
                'VALUE': values[1]
                },
                {
                'HEADER': 'Change Feed Settings',
                'VALUE': values[2]
                },
                {
                'HEADER': 'Create Feed',
                'VALUE': values[3]
                },
                {
                'HEADER': 'Create Text Channel',
                'VALUE': values[4]
                },
                {
                'HEADER': 'Delete Post',
                'VALUE': values[5]
                },
                {
                'HEADER': 'Remove/Ban Member',
                'VALUE': values[6]
                },
                {
                'HEADER': 'Invite New Users',
                'VALUE': values[7]
                },
                {
                'HEADER': 'Accept Join Request',
                'VALUE': values[8]
                },
                {
                'HEADER': 'Turn On Group Portfolios (Teams)',
                'VALUE': values[9]
                },
                {
                'HEADER': 'Create Group Portfolio',
                'VALUE': values[10]
                },
                {
                'HEADER': 'Share Portfolio',
                'VALUE': values[11]
                },
                {
                'HEADER': 'Delete Chat Messages',
                'VALUE': values[12]
                },
                {
                'HEADER': 'View Reported Posts',
                'VALUE': values[13]
                },
                {
                'HEADER': 'Report Post',
                'VALUE': values[14]
                },
                {
                'HEADER': 'Add/Remove Admin',
                'VALUE': values[15]
                },
                {
                'HEADER': 'Add/Remove Mod',
                'VALUE': values[16]
                },
            ]

            return TABLE_VALUES;
        }
    },

    methods: {
        open(member, num = 0) {
            this.step = num;
            this.display = true;
            this.selectedMember = member;

            const permissions = this.selectedMember.analyst.permissions
            const PERM_LABEL_MAP = {
                'inviteOthers': 'Invite others into the group',
                'removeMembers': 'Remove group members from the group',
                'editPerms': 'Edit others permissions'
            }

            for(const perm in permissions) {
                const hasPerm = permissions[perm] === true;

                this.originalPerms.push(permissions[perm]);
                this.permissionLabels.push(PERM_LABEL_MAP[perm]);
                this.selectedOptions.push({value: hasPerm === true ? 'Yes' :'No'});
                this.options.push(['Yes', 'No']);
            }

            if (this.selectedMember.roles.length > 1) {
                const NAME_MAP = {
                    [GROUP_ADMIN_ROLE_TYPE['ID']]: 'Admin',
                    [GROUP_MOD_ROLE_TYPE['ID']]: 'Moderator'
                };

                const role = this.selectedMember.roles.filter(role => [GROUP_MOD_ROLE_TYPE['ID'], GROUP_ADMIN_ROLE_TYPE['ID']].includes(role.roleId))[0];
                
                this.originalRole.id = role.roleId;
                this.originalRole.name = NAME_MAP[role.roleId];

                this.roleOption = this.originalRole;
            } else {
                this.originalRole.id = GROUP_MEMBER_ROLE_TYPE['ID'];
                this.originalRole.name = 'Member';

                this.roleOption = this.originalRole;
            }

            
            
        },
        closeDialog() {
            this.display = false;
        },

        onShown() {
            // this.step = 0;
        },
        onHide() {
            this.loading = false; 
            this.options = [];
            this.selectedOptions = [];
            this.permissionLabels = [];
            this.originalPerms = [];
            this.originalRole = {
                id: null,
                name: ''
            };
            this.roleOption = {
                id: null,
                name: ''
            };
        },

        previousStep() {
            this.step = 0;
        },

        getRoleOptions() {
            if (this.isGroupAdmin) {
                return [{ // yes let admins ad admins
                    id: GROUP_ADMIN_ROLE_TYPE['ID'],
                    name: 'Admin'
                },
                {
                    id: GROUP_MOD_ROLE_TYPE['ID'],
                    name: 'Moderator'
                },
                {
                    id: GROUP_MEMBER_ROLE_TYPE['ID'],
                    name: 'Member'
                }];
            } else {
                return [{
                    id: GROUP_ADMIN_ROLE_TYPE['ID'],
                    name: 'Admin'
                },
                {
                    id: GROUP_MOD_ROLE_TYPE['ID'],
                    name: 'Moderator'
                },
                {
                    id: GROUP_MEMBER_ROLE_TYPE['ID'],
                    name: 'Member'
                }];
            }
        },

        async updateGroupMemberRole() {
            this.loading = true;

            // If we're changing a role for a user that is only a group member, we only need to add the role we're updating them to 
            if (this.originalRole.id === GROUP_MEMBER_ROLE_TYPE['ID']) {
                if (this.roleOption.id === GROUP_ADMIN_ROLE_TYPE['ID']) {
                    GroupService.addAdminRole(this.selectedMember.analyst.userId, this.selectedGroup.groupId).then((resp) => {
                        if (resp.data.status === 'success') {
                            this.$toast.add({severity:'success', summary: 'You have successfully added '+ this.stringUtils.fullName(this.selectedMember.analyst) + "'s admin role!'", detail:'', life: 3000, group: 'tc'});
                            EventBus.emit('refresh-group-members-list');
                            this.display = false;
                        } else {
                            console.log("error adding admin role: " + resp.data.message);
                        }
                    })
                } else {
                    GroupService.addModRole(this.selectedMember.analyst.userId, this.selectedGroup.groupId).then((resp) => {
                        if (resp.data.status === 'success') {
                            this.$toast.add({severity:'success', summary: 'You have successfully added '+ this.stringUtils.fullName(this.selectedMember.analyst) + "'s mod role!'", detail:'', life: 3000, group: 'tc'});
                            EventBus.emit('refresh-group-members-list');
                            this.display = false;
                        } else {
                            console.log("error adding admin role: " + resp.data.message);
                        }
                    })
                }
            } else { // If the user we're updating their roles is either an admin or mod

                let addResp = null;
                let removeResp = null;
                let errorMsg = null;

                // This whole block focuses on removing the specific role whether they're an admin or mod
                if (this.originalRole.id === GROUP_ADMIN_ROLE_TYPE['ID']) {
                    errorMsg = "Removing admin role has failed, couldn't add mod role.";
                    removeResp = await GroupService.removeAdminRole(this.selectedMember.analyst.userId, this.selectedGroup.groupId);
                } else {
                    errorMsg = "Removing mod role has failed, couldn't add admin role.";
                    removeResp = await GroupService.removeModRole(this.selectedMember.analyst.userId, this.selectedGroup.groupId);
                }

                if (removeResp.data.status === 'success') {
                    if (this.roleOption.id === GROUP_ADMIN_ROLE_TYPE['ID']) {                    
                        addResp = await GroupService.addAdminRole(this.selectedMember.analyst.userId, this.selectedGroup.groupId);

                        if (addResp.data.status === 'success') {
                            this.$toast.add({severity:'success', summary: 'You have successfully updated '+ this.stringUtils.fullName(this.selectedMember.analyst) + " to admin role!'", detail:'', life: 3000, group: 'tc'});
                            EventBus.emit('refresh-group-members-list');
                            this.display = false;
                        } else {
                            this.loading = false; 
                            this.$toast.add({severity:'error', summary: 'Updating to admin role has failed.', detail: '', life: 3000, group: 'tc'});
                        }
                    } else if (this.roleOption.id === GROUP_MOD_ROLE_TYPE['ID']) {
                        addResp = await GroupService.addModRole(this.selectedMember.analyst.userId, this.selectedGroup.groupId);
                        

                        if (addResp.data.status === 'success') {
                            this.$toast.add({severity:'success', summary: 'You have successfully updated '+ this.stringUtils.fullName(this.selectedMember.analyst) + " to mod role!'", detail:'', life: 3000, group: 'tc'});
                            EventBus.emit('refresh-group-members-list');
                            this.display = false;
                        } else {
                            this.loading = false; 
                            this.$toast.add({severity:'error', summary: 'Updating to mod role has failed.', detail: '', life: 3000, group: 'tc'});
                        }
                    } else {
                        this.$toast.add({severity:'success', summary: 'You have successfully downgraded '+ this.stringUtils.fullName(this.selectedMember.analyst) + " to member!", detail:'', life: 3000, group: 'tc'});
                        EventBus.emit('refresh-group-members-list');
                        this.display = false;
                    }
                } else {
                    this.loading = false;
                    this.$toast.add({severity:'error', summary: errorMsg, detail: '', life: 3000, group: 'tc'});
                }
            }
        },

        removeOrBanMember() {
            this.loading = true;

            if(this.ban){
                GroupService.banFromGroup(this.selectedGroup.groupId, this.selectedMember.analyst.userId).then((resp) => {
                    this.loading = false;
                    if (resp.data.status === 'success') {
                        this.$toast.add({severity:'success', summary: 'You have successfully banned '+ this.stringUtils.fullName(this.selectedMember.analyst), detail:'', life: 3000, group: 'tc'});
                        EventBus.emit('refresh-group-members-list');
                        this.display = false;
                        
                    } else {
                        console.log("error banning member "+ resp.data.message);
                    }
                });
            } else {
                GroupService.removeMember(this.selectedGroup.groupId, this.selectedMember.analyst.userId).then((resp) => {
                    this.loading = false;
                    if (resp.data.status === 'success') {
                        this.$toast.add({severity:'success', summary: 'You have successfully removed '+ this.stringUtils.fullName(this.selectedMember.analyst), detail:'', life: 3000, group: 'tc'});
                        EventBus.emit('refresh-group-members-list');
                        this.display = false;
                        
                    } else {
                        console.log("error removing member "+ resp.data.message);
                    }
                });
            }
        }
    }
}
</script>

<style>
.member-actions-modal-0 {
    width: 300px;
    height: 180px;
}
.member-actions-modal-1 {
    width: 550px;
    max-height: 340px;
}
.member-actions-modal-2 {
    width: 480px;
}
.member-actions-modal-3 {
    width: 480px;
}

[class*=member-actions-modal] .p-dialog-header {
    padding: 16px;
    border-bottom: 1px solid #BFBFBF;
}

.member-actions-modal-0 .p-dialog-content,
.member-actions-modal-1 .p-dialog-content,
.member-actions-modal-2 .p-dialog-content {
    overflow-y: hidden;
    padding: 0px;
}
.member-actions-modal-3 .p-dialog-content {
    overflow-y: hidden;
    padding: 32px 16px 16px;
}

[class*=member-actions-modal] .p-dialog-footer {
    padding: 16px;
}
.member-actions-modal-1 .p-dialog-footer {
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid #BFBFBF;
}
.member-actions-modal-2 .p-dialog-footer {
    display: flex;
    justify-content: center;
    width: 100%;
}
[class*=member-actions-modal] .p-dialog-footer button {
    border-radius: 50px;
}
.member-actions-modal-0 .p-dialog-footer button {
    width: 100%;
}
.member-actions-modal-1 .p-dialog-footer button, 
.member-actions-modal-2 .p-dialog-footer button {
    width: 50%;
    margin: 0px;
}
.member-actions-modal-1 .p-dialog-footer button:first-child, 
.member-actions-modal-2 .p-dialog-footer button:first-child {
    margin-right: 16px;
}

.role-dropdown-panel .p-dropdown-items {
    padding: 0px;
    border-radius: 4px;
}

@media (max-width: 760px) {
    [class*=member-actions-modal] {
        width: 100%;
    }

    [class*=member-actions-modal] .p-dialog-header {
        border-radius: 0;
    }

    .member-actions-modal-3 .p-dialog-content {
        padding: 32px !important;
    }

    [class*=member-actions-modal] .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

</style>
<style scoped>

.p-button {
    border-width: 1px;
    border-style: solid;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}
/* .red-button__primary {
    border-color: #E63E3E;
    background: #E63E3E;
    color: #FFFFFF;
    border-radius: 50px;
}
.red-button__primary:hover {
    background: #D23636;
    border-color: #D23636;
}
.red-button__primary:focus {
    box-shadow: none;
    border-color: #D23636;
}
.red-button__secondary {
    border-color: #E63E3E;
    background: #FFFFFF;
    color: #32364e;
    border-radius: 50px;
}
.red-button__secondary:hover {
    background: #E63E3E;
    border-color: #E63E3E;
}
.red-button__secondary:focus {
    box-shadow: none;
    border-color: #E63E3E;
}
.green-button__primary {
    color: #FFFFFF;
    background: #33CC99;
    border-radius: 8px;
    border-color: #33CC99;
}
.green-button__primary:hover {
    background: #00C083;
    border-color: #00C083;
}
.green-button__primary:focus {
    box-shadow: none;
    border-color: #00C083;
}
.blue-button {
    background: #FFFFFF;
    color: #32364e;
    border-color: #32364e;
}
.blue-button:hover {
    background: #32364e;
    color: #FFFFFF;
}
.blue-button:focus {
    box-shadow: none;
    border-color: #32364e;
} */

.permission-item {
    display: flex;
    align-items: center;
    padding: 16px;
    width: 100%;
    border-bottom: 1px solid #BFBFBF;
}
.permission-item:last-child {
    border-bottom: none;
}

.permission-label {
    font-weight: bold;
    color: #32364e;
    font-size: 16px;
}

.permission-item .p-selectbutton {
    margin-left: auto;
}

.permission-item .p-selectbutton ::v-deep(.p-button) {
    border-radius: 8px;
    color: #32364e;
}

.permission-item .p-selectbutton ::v-deep(.p-button:first-child) {
    background: #FFFFFF;
    border-color: #33CC99;
    margin-right: 16px;
}
.permission-item .p-selectbutton ::v-deep(.p-button:first-child.p-highlight),
.permission-item .p-selectbutton ::v-deep(.p-button:first-child:hover) {
    background: #33CC99;
    border-color: #33CC99;
    color: #FFFFFF;
}
.permission-item .p-selectbutton ::v-deep(.p-button:first-child:focus) {
    box-shadow: none;
    border-color: #33CC99;
}
.permission-item .p-selectbutton ::v-deep(.p-button:last-child) {
    border-color: #E63E3E;
    background: #FFFFFF;
}
.permission-item .p-selectbutton ::v-deep(.p-button:last-child.p-highlight),
.permission-item .p-selectbutton ::v-deep(.p-button:last-child:hover) {
    background: #E63E3E;
    border-color: #E63E3E;
    color: #FFFFFF;
}
.permission-item .p-selectbutton ::v-deep(.p-button:last-child:focus) {
    box-shadow: none;
    border-color: #E63E3E;
}
.permission-item .p-buttonset ::v-deep(.p-button:not(:last-child)) {
    border-right: 1px solid #33CC99;
}

.actions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.actions-container .p-button {
    margin-bottom: 16px;
}
.actions-container .p-button:last-child {
    margin-bottom: 0;
}

.ban-checkbox-container {
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ban-checkbox-label {
    margin-left: 8px;
    cursor: pointer;
}

.role-dropdown {
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 100%;
}
/* change roles UI styling */
.role-dropdown ::v-deep(.p-inputtext) {
    padding: 0;
}
.role-dropdown:hover, .role-dropdown:active {
    border-color: #33CC99;
}
.role-dropdown:focus, .role-dropdown:not(.p-disabled).p-focus, .p-inputwrapper-focus {
    border-color: #33CC99;
    box-shadow: none;
}
::v-deep(.p-dropdown-label.p-placeholder) {
    color: #BFBFBF;
    padding: 0px;
}
::v-deep(.p-dropdown-item) {
    color: #32364e;
    border-bottom: 1px solid #333;
}

.permissions-table {
    width: 100%;
}
.permissions-table td {
    text-align: center;
}
.permissions-table td:last-of-type {
    font-weight: bold;
}
.permissions-table td .pi-times {
    color: #E63E3E;
}
.permissions-table td .pi-check {
    color: #33CC99;
}

</style>