<template>
    <div :style="style">
        <div class="top-container">
            <SearchBar ref='groupMembersSearchBar' @search-input="searchInput" @loading="onLoading" placeholderText="Search for a group member" />
            <div class="include-banned-checkbox-container" v-if="isGroupOwner || hasAdminModRights($store.getters['users/groupRoleList'])">
                <Checkbox inputId="include-banned-checkbox" v-model="includeBanned" :binary="true" />
                <label for="include-banned-checkbox" class="include-banned-checkbox-label">Include banned</label>
            </div><br>
            
        </div>
        <div style="padding: 5px;" v-if="totalCount > 0 && searchResults?.length > 0">Showing {{searchResults.length}} of {{totalCount}} members.</div>
        <LoadingSpinner :showLoading="initLoading"/>
        <infinite-scroll class="members-scroll" @infinite-scroll="loadMoreResults" message="" :noResult="noMoreSearchResults" :style='scrollStyle' >
            <UserListItem :user='member.analyst' v-for='member in searchResults' :key='member.analyst.userId' >
                <template #afterFullName>
                    <Chip class="role-pill" v-if="hasOwnerRights(member.roles)" label="OWNER"></Chip>
                    <Chip class="role-pill" v-else-if="userUtils.isGroupAdmin(member.roles)" label="ADMIN" image="/adminIcon.png"></Chip>
                    <Chip class="role-pill" v-else-if="userUtils.isGroupMod(member.roles)" label="MODERATOR" image="/moderatorIcon.png"></Chip>
                </template>
                <template #actions>
                    <FollowButton :followTarget="member.analyst" :confirmUnfollow="false" :followVal="member.analyst.activityStats.following"/>
                    <template v-if="!hasOwnerRights(member.roles)">
                        <Button class='user-actions-button red-button__secondary' icon='pi pi-ban' @click='openUnBanUserModal(member)' v-tooltip.bottom="'Unban user'" v-if="(isGroupOwner || hasAdminModRights($store.getters['users/groupRoleList'])) && isBanned(member)" />
                        <template v-else-if="isGroupOwner || hasAdminModRights($store.getters['users/groupRoleList'])">
                            <Button class='user-actions-button blue-button' icon='pi pi-cog' @click='openActionsModal(member)' v-tooltip.bottom="'Member Actions'" v-if="canAddRemoveRoles(member)"/>
                            <Button class='user-actions-button red-button__secondary' icon='pi pi-delete-left' @click='openActionsModal(member)' v-tooltip.bottom="'Remove/Ban user'" v-else/>
                        </template>
                    </template>
                        
                </template>
            </UserListItem>
            <LoadingSpinner :showLoading="loading" minHeight='100px'/>
        </infinite-scroll>
    </div>

    <MemberActionsModal :isGroupAdmin="userUtils.isGroupAdmin($store.getters['users/groupRoleList'])" ref='memberActionsModal'/>
    <ConfirmModal headerText='Remove ban for user?' confirmButtonLabel='Yes' @confirmed="unBanUser()" ref='unBanUserModal' />
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner.vue';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import MemberActionsModal from './modal/MemberActionsModal.vue';
import SearchBar from '../query/SearchBar.vue';
import UserListItem from '../list/UserListItem.vue';
import FollowButton from '../button/FollowButton.vue';
import Checkbox from 'primevue/checkbox';
import ConfirmModal from '../modal/ConfirmModal.vue';
import Chip from 'primevue/chip';

import GroupService from '../../service/GroupService';
import StringUtils from '../../utilities/StringUtils';
import UserUtils from '../../utilities/UserUtils';
import {USER_TYPES, GROUP_OWNER_ROLE_TYPE} from '../../common/constants.js';

import EventBus from '../../event-bus';
import { mapState } from 'vuex';
import Mark from 'mark.js';

export default {
    name: 'CurrentMemberSearch',
    
    components: {
        LoadingSpinner, InfiniteScroll, MemberActionsModal, SearchBar, UserListItem, FollowButton, Checkbox, ConfirmModal, Chip
    },
     props: {
        context: {
            type: String,
            required: true
        },

        style: {
            type: String,
            required: false
        }


    },

    data() {
        return {
            //CONSTANTS
            USER_TYPES,

            placeholderText: 'Search group members',
            includeBanned: false,
            selectedMember: null,
            loading: false,
            initLoading: false,
            currentText: '',
            searchResults: [],
            totalCount: 0,
            noMoreSearchResults: true,
            scrollStyle: "width: 100%; max-height: 100%; height: 480px;",

            // UTILITIES/SERVICES
            stringUtils: StringUtils,
            userUtils: UserUtils
        }
    },
    computed: {
        ...mapState(['selectedGroup']),

        isGroupOwner() {
            return UserUtils.isUser(this.selectedGroup.groupOwnerId);
        }
      
    },
    watch: {
        includeBanned(newVal, oldVal){
            if( newVal != oldVal && !this.loading) {
                console.log("include banned changed")
                this.initLoadMembers();
            }
        }
    },

    created() {
        EventBus.off('refresh-group-members-list');
        EventBus.on('refresh-group-members-list', this.onRefreshGroupMembersList);
    },
    mounted() {
        this.initLoadMembers();

       
    },

    methods: {
        onRefreshGroupMembersList() {
            console.log("onrerfreshgroupmemberslist")
            this.initLoadMembers();
        },

        canAddRemoveRoles(member) {//eslint-disable-line
            if (this.isGroupOwner) {
                return true;
            } else if (UserUtils.isGroupAdmin(this.$store.getters['users/groupRoleList'])) {
                return true;
            } else {
                return false;
            }
        },

        refresh() {
            this.initLoadMembers();
        },

        hasAdminModRights(roles) {
            return UserUtils.isGroupAdmin(roles) || UserUtils.isGroupMod(roles);
        },

        hasOwnerRights(roles) {
            return roles.some(role => role.roleId === GROUP_OWNER_ROLE_TYPE['ID']);
        },
        

        isBanned(member) {
            if(member.banStartDate){
                if(!member.banStopDate) return true;
                // is banStopDate before current date
                const banStopDate = Date.parse(member.banStopDate);
                const nowPlus5Hours = new Date();
                nowPlus5Hours.setHours(nowPlus5Hours.getHours() + 5);
                console.log("isBanned", nowPlus5Hours < banStopDate, banStopDate, nowPlus5Hours);
                return nowPlus5Hours < banStopDate;
            }
        },

        onLoading() {
            this.searchResults = [];
            this.initLoading = true;
            this.loading = false;
        },
        searchInput(val) {
            this.currentText = val;
            this.fetchGroupMembersFromSearch(val, 10, 0);
        },

        initLoadMembers() {
           // this.currentText = '';
           console.log("init load members")
           this.noMoreSearchResults = false;
            this.loading = false;
            this.searchResults = [];
            this.totalCount = 0;
            this.initLoading = true;
            this.fetchGroupMembersFromSearch(this.currentText, 10, 0);
        },

        openActionsModal(member) {
            if (this.canAddRemoveRoles(member)) {
                this.$refs.memberActionsModal.open(member);
            } else {
                this.$refs.memberActionsModal.open(member, 2);
            }
        },

        openUnBanUserModal(member) {
            this.selectedMember = member.analyst;
            this.$refs.unBanUserModal.open();
        },

        unBanUser() {
            GroupService.unBanFromGroup(this.selectedGroup.groupId, this.selectedMember.userId).then((resp) => {
                this.loading = false;
                if (resp.data.status === 'success') {
                    this.$toast.add({severity:'success', summary: 'You have successfully unbanned '+ this.stringUtils.fullName(this.selectedMember), detail:'', life: 3000, group: 'center'});
                    EventBus.emit('refresh-group-members-list');
                    this.display = false;
                    
                } else {
                    console.log("error unbanning member "+ resp.data.message);

                    if(resp.data.message && resp.data.message.startsWith("Team members limit is")){
                        this.$toast.add({ severity: 'info', summary: "Oops! This team is full with 20 members. You need to remove one to unban this member.", life: 4000, group: 'center'});
                    }
                }
            });
        },

        async loadMoreResults() {
            if (!this.loading) {
                this.loading = true;

                if (!this.noMoreSearchResults) {
                    this.fetchGroupMembersFromSearch(this.currentText, 10, this.searchResults.length);
                } else {
                    this.loading = false;
                }
            }
        },

        fetchGroupMembersFromSearch(query, limit, offset) {
            GroupService.searchGroupMembers(this.selectedGroup.groupId, offset, limit, query, this.includeBanned).then( resp => {
                this.initLoading = false;
                this.loading = false;
                const members = resp.data.members;
                console.log("received "+ members.length+" members ")
                if (members.length > 0) {
                    const results = this.searchResults.concat(members);
                    this.totalCount = resp.data.totalCount;
                    this.searchResults = results;
                    console.log("now showing "+ this.searchResults.length+" members out of "+resp.data.totalCount)

                    if(this.searchResults.length < resp.data.totalCount) {
                        this.noMoreSearchResults = false;
                    }
                    else {
                        this.noMoreSearchResults = true;
                    }

                    // Highlighting of matches in results
                    this.$nextTick(() => {
                        let instance = new Mark(document.querySelectorAll('.user-list-item-container .text-container'));

                        const regex = new RegExp(`\\b${query}|(?<=[@(])\\b${query}`, 'gi');

                        instance.unmark();
                        instance.markRegExp(regex, {
                            'className': 'search-match-highlight'
                        });
                    })
                } else {
                    this.noMoreSearchResults = true;
                }
            })
        },

    }

}
</script>

<style scoped>
::v-deep(.list-item-container) {
    border-bottom: 1px solid #BFBFBF;
}
::v-deep(.list-item-container:last-child) {
    border-bottom: none;
}

.top-container {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #BFBFBF;
}

::v-deep(.search-bar-container){
    flex-grow: 1;
    border-bottom: unset;
}

.include-banned-checkbox-container {
    display: flex;
    align-items: center;
    padding: 0px 16px 16px 0px;
}

.include-banned-checkbox-label {
    margin-left: 4px;
    cursor: pointer;
}

.user-actions-button {
    /* border: 1px solid #e63e3e; */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    /* background: #FFFFFF;
    color: #e63e3e; */
}

.role-pill {
    padding: 0px 8px;
    border: 1px solid #32364e;
    background: #FFFFFF;
    border-radius: 20px;
}
.role-pill ::v-deep(img) {
    width: 16px;
    margin-right: 4px;
}
.role-pill ::v-deep(.p-chip-text) {
    font-size: 11px;
}
/* .user-actions-button:hover {
    background: #e63e3e;
    border-color: #e63e3e;
}
.user-actions-button:focus {
    box-shadow: none;
    border-color: #e63e3e;
} */

@media (max-width: 760px) {
    .user-list-item-container ::v-deep(.header-text) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
</style>